<template>
  <div id="app">
    <p>{{ message }}</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      message: 'Loading...'
    };
  },
  created() {
    axios.get('/api/message')
      .then(response => {
        this.message = response.data.message;
      })
      .catch(error => {
        console.error(error);
        this.message = 'Error loading message.';
      });
  }
};
</script>

<style>
/* Ensure the html and body take up full height */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Style the app container */
#app {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;     /* Center vertically */
  height: 100%;            /* Take up full viewport height */
  background-color: #333;  /* Dark gray background */
}

/* Style the message text */
p {
  color: #fff;             /* White text */
  font-size: 1.5em;        /* Increase font size */
  text-align: center;      /* Center text */
  margin: 0;
  font-family: 'Courier New', Courier, monospace;
}
</style>
